import { DropzoneArea } from 'material-ui-dropzone'

import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Button
} from '@mui/material'

interface UploadDialogProps {
    open: boolean
    acceptedFiles: string[]
    handleSave: (files: any) => void
    handleClose: () => void
    filesLimit: number
    description?: React.ReactNode
    title?: string
}

const UploadDialog = ({
    open,
    acceptedFiles,
    handleSave,
    handleClose,
    filesLimit,
    description,
    title = 'Upload Files'
}: UploadDialogProps) => {
    const [files, setFiles] = React.useState<File[]>([])
    const handleDrop = (droppedFiles: File[]) => setFiles(droppedFiles)
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {description && (
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        {description}
                    </Typography>
                )}
                <DropzoneArea
                    acceptedFiles={acceptedFiles}
                    maxFileSize={5000000}
                    showAlerts={false}
                    filesLimit={filesLimit}
                    onChange={handleDrop}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={!files.length}>
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadDialog
