import { IChangeEvent } from '@rjsf/core'
import { RJSFSchema } from '@rjsf/utils'

/**
 * Serializes the key-value pairs from an IChangeEvent's formData into a JSON string.
 *
 * @param data - The IChangeEvent object containing the formData to serialize.
 * @returns A JSON string containing the key-value pairs from the formData.
 */
export const serializeData = (data: IChangeEvent<any, RJSFSchema, any>) => {
    const formattedData = data?.formData?.attributes?.map(
        ({ key, value_path, value_string, value_boolean, value_number }) => {
            // Ensure backwards compatibility
            const isValuePathObject = typeof value_path === 'object' && value_path !== null

            if (isValuePathObject && 'path' in value_path && 'model_code' in value_path) {
                return {
                    key,
                    value: value_path.path,
                    model_code: value_path.model_code
                }
            }

            return {
                key,
                value: isValuePathObject
                    ? JSON.stringify(value_path)
                    : value_path || value_string || value_boolean || value_number
            }
        }
    )

    return JSON.stringify(formattedData)
}
