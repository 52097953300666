import {
    List,
    Datagrid,
    FunctionField,
    ListProps,
    TextField,
    CreateButton,
    ExportButton,
    FilterButton,
    TopToolbar,
    Button,
    sanitizeListRestProps,
    Pagination
} from 'react-admin'
import { Record, useTranslate, useNotify } from 'ra-core'
import datagridStyles from '../../layout/styles/datagridStyles'
import SchoolClassListFilters from './SchoolClassListFilters'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useState } from 'react'
import UploadDialog from '../../components/common/UploadDialog'
import { useSchoolsImportSchoolClassesMutation } from '../../apollo/upload/mutations/SchoolsImportSchoolClasses.generated'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { Typography } from '@mui/material'

const ListActions = (props) => {
    const t = useTranslate()
    const { className, maxResults, forceUpdate, ...rest } = props
    const [openDialog, setOpenDialog] = useState(false)
    const [uploadSchoolClasses] = useSchoolsImportSchoolClassesMutation()
    const notify = useNotify()

    const handleUpload = (files) => {
        if (files?.[0]) {
            uploadSchoolClasses({
                variables: {
                    file: files?.[0]
                }
            })
                .then(() => {
                    setOpenDialog(false)
                    forceUpdate()
                })
                .catch((error) => notify(error.message, 'error'))
        }
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CreateButton />
            <ExportButton />
            <Button
                onClick={() => {
                    setOpenDialog(true)
                }}
                label={t('manager.resources.schoolClass.upload')}
            >
                <CloudUploadIcon />
            </Button>
            <UploadDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                acceptedFiles={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ]}
                handleSave={handleUpload}
                filesLimit={1}
                title={'Classes Import'}
                description={
                    <>
                        <Typography gutterBottom>
                            The section is used to batch insert/update the list of Schools' Classes
                            from an XLS file.
                        </Typography>
                        <Typography gutterBottom>
                            Please, choose an Excel file to import classes from.
                            <br />
                            The first row of this file can contain headers only and no significant
                            data. The rows must contain school data in the following order:
                        </Typography>
                        <ul style={{ marginTop: 8, marginBottom: 8, paddingLeft: 20 }}>
                            <li>
                                <b>A</b>: School AJAT-ID
                            </li>
                            <li>
                                <b>B</b>: School Year ID (e.g. 2025)
                            </li>
                            <li>
                                <b>C</b>: Name
                            </li>
                            <li>
                                <b>D</b>: Embroidery 1
                            </li>
                            <li>
                                <b>E</b>: Embroidery 1
                            </li>
                            <li>
                                <b>F</b>: Embroidery 1
                            </li>
                            <li>
                                <b>G</b>: Students Amount (Integer)
                            </li>
                            <li>
                                <b>H</b>: Active (1/0)
                            </li>
                        </ul>
                        <Typography>
                            To reset a value set the "NULL" string (uppercase) in the cell.
                        </Typography>
                        <Typography>
                            Please use this{' '}
                            <a
                                href={process.env.PUBLIC_URL + '/classes-import-example.xlsx'}
                                download={'classes-import-example.xlsx'}
                            >
                                template
                            </a>
                            .
                        </Typography>
                    </>
                }
            />
        </TopToolbar>
    )
}

const SchoolClassList = (props: ListProps) => {
    const RESOURCE = 'SchoolClass'

    const datagridClasses = datagridStyles()
    const translate = useTranslate()

    const forceUpdate = () => window.location.reload()

    return (
        <List
            {...props}
            basePath={`/${RESOURCE}`}
            resource={RESOURCE}
            sort={{ field: 'name', order: 'DESC' }}
            perPage={DEFAULT_PER_PAGE}
            filter={props.filter}
            filters={SchoolClassListFilters}
            bulkActionButtons={<BulkActionButtons />}
            actions={props.filter ? <></> : <ListActions forceUpdate={forceUpdate} />}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
        >
            <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                <TextField source="name" label={translate('manager.resources.schoolClass.name')} />
                <FunctionField
                    source="school"
                    label={translate('manager.resources.schoolClass.school')}
                    sortable={false}
                    render={(record?: Record | undefined) =>
                        `${record?.school?.ajat_id} | ${record?.school?.name}`
                    }
                />
                <FunctionField
                    source="school"
                    label={translate('manager.resources.schoolClass.year')}
                    sortable={false}
                    render={(record?: Record | undefined) => record?.year?.name}
                />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record?: Record | undefined) => (
                        <DatagridPublishedColumnContent record={record} />
                    )}
                />
                <TextField
                    source="priority"
                    label={translate('manager.resources.schoolClass.priority')}
                />
                <DateFieldCustom
                    label={translate('manager.resources.general.last_updated')}
                    source="updated_at"
                    showTime
                    transform={(value) => formatDateString(value, true)}
                />
            </Datagrid>
        </List>
    )
}
export default SchoolClassList
