import React, { useState } from 'react'
import { Box, CircularProgress, Divider, MenuItem, Select, Typography } from '@mui/material'
import { WidgetProps } from '@rjsf/utils'
import { useConfiguratorModelTreeStructureQuery } from 'apollo/package_manager/queries/ConfiguratorModelTreeStructure.generated'
import { useRootNodesQuery } from 'apollo/configurator/queries/RootNodes.generated'
import CheckboxTree from 'react-checkbox-tree'
import { useTranslate } from 'react-admin'
import { ChoiceType } from 'types'

export const PathWidget = ({ id, value, onChange }: WidgetProps) => {
    const [expanded, setExpanded] = useState<string[]>([])
    const translate = useTranslate()
    const { data: { configuratorNodes: nodes } = {} } = useRootNodesQuery()
    const choices =
        nodes?.map(({ code }): ChoiceType => ({ id: code ?? '', name: code ?? '' })) || []

    const { data: { configuratorModelTreeStructure } = {}, error } =
        useConfiguratorModelTreeStructureQuery({
            variables: { code: typeof value === 'object' ? value?.model_code || '' : '' },
            skip: typeof value !== 'object' || !value?.model_code
        })

    if (error) {
        return (
            <>{`No model tree found for ${
                typeof value === 'object' ? value?.model_code : value
            }`}</>
        )
    }

    return (
        <React.Fragment key={id}>
            {choices.length ? (
                <>
                    <Select
                        key={`${id}-select`}
                        fullWidth
                        variant="outlined"
                        value={typeof value === 'object' ? value?.model_code || '' : ''}
                        onChange={(event) =>
                            onChange({
                                model_code: event.target.value,
                                path: typeof value === 'string' ? value : ''
                            })
                        }
                        displayEmpty
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>
                            No model selected
                        </MenuItem>

                        {choices.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>

                    {configuratorModelTreeStructure && (
                        <CheckboxTree
                            showNodeIcon={false}
                            showExpandAll={false}
                            noCascade
                            checkModel="all"
                            nodes={JSON.parse(configuratorModelTreeStructure || '[]')}
                            checked={typeof value === 'object' && value?.path ? [value.path] : []}
                            expanded={expanded}
                            onCheck={(_, node) =>
                                onChange({ model_code: value?.model_code, path: node.value })
                            }
                            onExpand={setExpanded}
                        />
                    )}

                    {/* Backwards compatibility */}
                    {typeof value === 'string' && value && (
                        <Box sx={{ mt: 2 }}>
                            <Divider />
                            <Typography variant="caption">
                                <strong>
                                    {translate(
                                        'manager.resources.rule.actions_tab.path_tree.path_hint'
                                    )}
                                    :
                                </strong>
                                {value}
                            </Typography>
                        </Box>
                    )}

                    {typeof value === 'object' && value?.path && (
                        <Box sx={{ mt: 2 }}>
                            <Divider />
                            <Typography variant="caption">
                                <strong>
                                    {translate(
                                        'manager.resources.rule.actions_tab.path_tree.path_hint'
                                    )}
                                    :
                                </strong>
                                {value.path}
                            </Typography>
                        </Box>
                    )}
                </>
            ) : (
                <CircularProgress />
            )}
        </React.Fragment>
    )
}
