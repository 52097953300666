import {
    List,
    Datagrid,
    FunctionField,
    ListProps,
    TextField,
    CreateButton,
    ExportButton,
    FilterButton,
    TopToolbar,
    Button,
    sanitizeListRestProps,
    Pagination
} from 'react-admin'
import { Record, useNotify, useTranslate } from 'ra-core'
import Chip from '@mui/material/Chip'
import { makeStyles } from '@material-ui/core/styles'

import SchoolListFilters from './SchoolListFilters'
import datagridStyles from '../../layout/styles/datagridStyles'
import DatagridPublishedColumnContent from '../../components/common/DatagridPublishedColumnContent'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Fragment, useState } from 'react'
import UploadDialog from '../../components/common/UploadDialog'
import { useSchoolsImportSchoolsMutation } from '../../apollo/upload/mutations/SchoolsImportSchools.generated'
import { formatDateString } from 'utils/formatDateString'
import DateFieldCustom from 'utils/overrides/DateFieldCustom'
import { Typography } from '@mui/material'

const useStyles = makeStyles({
    chip: { marginRight: '1px' },
    schoolsList: {
        '& .MuiToolbar-regular form': {
            display: 'grid',
            'grid-template-columns': '1fr 1fr',
            width: '100%'
        },
        '& .MuiFormControl-marginDense': {
            width: '100%'
        },
        '& .filter-field': {
            alignItems: 'center'
        }
    }
})

const ListActions = (props) => {
    const t = useTranslate()
    const { className, maxResults, forceUpdate, ...rest } = props
    const [openDialog, setOpenDialog] = useState(false)
    const [uploadSchools] = useSchoolsImportSchoolsMutation()
    const notify = useNotify()

    const handleUpload = (files) => {
        if (files?.[0]) {
            uploadSchools({
                variables: {
                    file: files?.[0]
                }
            })
                .then(() => {
                    setOpenDialog(false)
                    forceUpdate()
                })
                .catch((error) => notify(error.message, 'error'))
        }
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <FilterButton />
            <CreateButton />
            <ExportButton />
            <Button
                onClick={() => {
                    setOpenDialog(true)
                }}
                label={t('manager.resources.school.upload')}
            >
                <CloudUploadIcon />
            </Button>
            <UploadDialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                acceptedFiles={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ]}
                handleSave={handleUpload}
                filesLimit={1}
                title={'Schools Import'}
                description={
                    <>
                        <Typography gutterBottom>
                            The section is used to batch insert/update the list of Schools from an
                            XLS file.
                        </Typography>
                        <Typography gutterBottom>
                            Please, choose an Excel file to import schools from.
                            <br />
                            The first row of this file can contain headers only and no significant
                            data. The rest of the rows must contain school data in the following
                            order:
                        </Typography>
                        <ul style={{ marginTop: 8, marginBottom: 8, paddingLeft: 20 }}>
                            <li>
                                <b>A</b>: Ajat Id
                            </li>
                            <li>
                                <b>B</b>: Name
                            </li>
                            <li>
                                <b>C</b>: Active (1/0)
                            </li>
                            <li>
                                <b>D</b>: Address
                            </li>
                            <li>
                                <b>E</b>: Post Code
                            </li>
                            <li>
                                <b>F</b>: City
                            </li>
                            <li>
                                <b>G</b>: Country
                            </li>
                            <li>
                                <b>H</b>: Students Amount
                            </li>
                            <li>
                                <b>I</b>: Marketing Url
                            </li>
                            <li>
                                <b>J</b>: Embroidery 1
                            </li>
                            <li>
                                <b>K</b>: Embroidery 2
                            </li>
                            <li>
                                <b>L</b>: Embroidery 3
                            </li>
                            <li>
                                <b>M</b>: Internal Note
                            </li>
                            <li>
                                <b>N</b>: Hero Banner
                            </li>
                            <li>
                                <b>O</b>: Representative Name
                            </li>
                            <li>
                                <b>P</b>: Representative Email
                            </li>
                            <li>
                                <b>Q</b>: Representative Telephone
                            </li>
                            <li>
                                <b>R</b>: Representative Ssn
                            </li>
                            <li>
                                <b>S</b>: Representative Discount Amount
                            </li>
                            <li>
                                <b>T</b>: Logo
                            </li>
                            <li>
                                <b>U</b>: Personalization Logo
                            </li>
                            <li>
                                <b>V</b>: Banner (currently not used)
                            </li>
                            <li>
                                <b>W</b>: Tags (CSV with ; separator)
                            </li>
                            <li>
                                <b>X</b>: Sales Responsible
                            </li>
                        </ul>
                        <Typography>
                            To reset a value set the "NULL" string (uppercase) in the cell.
                        </Typography>
                        <Typography>
                            Please use this{' '}
                            <a
                                href={process.env.PUBLIC_URL + '/schools-import-example.xlsx'}
                                download={'schools-import-example.xlsx'}
                            >
                                template
                            </a>
                            .
                        </Typography>
                    </>
                }
            />
        </TopToolbar>
    )
}
const SchoolList = (props: ListProps) => {
    const translate = useTranslate()
    const datagridClasses = datagridStyles()
    const classes = useStyles()

    // Reload page after schools are updated so that all the imported data is showing.
    const forceUpdate = () => window.location.reload()

    return (
        <List
            {...props}
            sort={{ field: 'name', order: 'DESC' }}
            perPage={DEFAULT_PER_PAGE}
            filters={SchoolListFilters}
            bulkActionButtons={<BulkActionButtons />}
            actions={<ListActions forceUpdate={forceUpdate} />}
            className={classes.schoolsList}
            pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
        >
            <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                <TextField source="name" label={translate('manager.resources.school.name')} />
                <FunctionField
                    source="active"
                    label={translate('manager.resources.general.status')}
                    render={(record?: Record | undefined) => (
                        <DatagridPublishedColumnContent record={record} key={record?.id} />
                    )}
                />
                <FunctionField
                    label={translate('manager.resources.school.active_periods')}
                    render={(record?: Record | undefined) =>
                        record?.periods && record?.periods?.length > 0 ? (
                            record.periods.map((period) =>
                                period.active ? (
                                    <div style={{ whiteSpace: 'nowrap' }}>
                                        <Chip
                                            size="small"
                                            label={formatDateString(period.from_date)}
                                        />
                                        <span> - </span>
                                        <Chip
                                            size="small"
                                            label={formatDateString(period.to_date)}
                                        />
                                    </div>
                                ) : (
                                    false
                                )
                            )
                        ) : (
                            <> - </>
                        )
                    }
                />
                <TextField source="ajat_id" label={translate('manager.resources.school.ajat_id')} />
                <TextField
                    source="priority"
                    label={translate('manager.resources.school.priority')}
                />
                <FunctionField
                    label={translate('manager.resources.school.tags')}
                    render={(record?: Record | undefined) =>
                        record?.tags && record.tags.length > 0 ? (
                            record.tags.map((t) => (
                                <Chip
                                    size="small"
                                    label={t.tag}
                                    className={classes.chip}
                                    key={record.id}
                                />
                            ))
                        ) : (
                            <> - </>
                        )
                    }
                />
                <DateFieldCustom
                    label={translate('manager.resources.general.last_updated')}
                    source="updated_at"
                    showTime
                    transform={(value) => formatDateString(value, true)}
                />
            </Datagrid>
        </List>
    )
}
export default SchoolList
